export const environment = {
  production: false,
  marketplaceIdLocal: 'marketplaceId',
  urlApigee: 'https://apidev.fastshop.com.br',
  uri: 'v1/sellercenter/iam/fastid/fastshop',
  marketplaceId: 'sellercenter-develop',
  sapCrmApi: 'https://apiqa.fastshop.com.br/v1',
  pathSc: '/sellercenter/v0',
  pathAddress: '/customer/v0',
  pathLocate: '/locate/v0',
  pathSimpleFreight: '/shipping/v0/shippings/sellersimplefreight',
  pathShopMpc: '/mpc/v0/shop-mpc/fastshop/catalog/1',
  pathShopMpcCategories: '/categories',
  pathShopMpcItensSkus: '/items/skus',
  pathShopMpcItensBrands: '/items/brands',
  bucketName: 'seller-simple-freight-calculator-qa',
  bucketRegion: 'us-east-1',
  accessKeyId: 'AKIAUROUJ52VR35S25FN',
  secretAccessKey: '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'
};
